/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimCardNetworkStateIp
 */
export interface SimCardNetworkStateIp {
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateIp
     */
    ip?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateIp
     */
    issuedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateIp
     */
    expiresAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateIp
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateIp interface.
 */
export function instanceOfSimCardNetworkStateIp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateIpFromJSON(json: any): SimCardNetworkStateIp {
    return SimCardNetworkStateIpFromJSONTyped(json, false);
}

export function SimCardNetworkStateIpFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateIp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'issuedAt': !exists(json, 'issuedAt') ? undefined : (new Date(json['issuedAt'])),
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateIpToJSON(value?: SimCardNetworkStateIp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ip': value.ip,
        'issuedAt': value.issuedAt === undefined ? undefined : (value.issuedAt.toISOString()),
        'expiresAt': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

