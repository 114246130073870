/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageType } from './MessageType';
import {
    MessageTypeFromJSON,
    MessageTypeFromJSONTyped,
    MessageTypeToJSON,
} from './MessageType';
import type { SendSmsActionMsisdnSelectionInner } from './SendSmsActionMsisdnSelectionInner';
import {
    SendSmsActionMsisdnSelectionInnerFromJSON,
    SendSmsActionMsisdnSelectionInnerFromJSONTyped,
    SendSmsActionMsisdnSelectionInnerToJSON,
} from './SendSmsActionMsisdnSelectionInner';
import type { SimCardActionRequestFilter } from './SimCardActionRequestFilter';
import {
    SimCardActionRequestFilterFromJSON,
    SimCardActionRequestFilterFromJSONTyped,
    SimCardActionRequestFilterToJSON,
} from './SimCardActionRequestFilter';

/**
 * The message to send to the SIM Cards targeted by the specified filter
 * @export
 * @interface SendSmsAction
 */
export interface SendSmsAction {
    /**
     * 
     * @type {SimCardActionRequestFilter}
     * @memberof SendSmsAction
     */
    simCardFilter?: SimCardActionRequestFilter;
    /**
     * The payload to send, if GSM_7_BIT is selected as messageType, the payload should fit within that alphabet, otherwise use the UCS2 messageType
     * @type {string}
     * @memberof SendSmsAction
     */
    payload: string;
    /**
     * 
     * @type {MessageType}
     * @memberof SendSmsAction
     */
    messageType: MessageType;
    /**
     * Pre-select the MSISDN for each ICC, currently selection is limited to 5000 entries at a time.
     * @type {Set<SendSmsActionMsisdnSelectionInner>}
     * @memberof SendSmsAction
     */
    msisdnSelection?: Set<SendSmsActionMsisdnSelectionInner>;
}

/**
 * Check if a given object implements the SendSmsAction interface.
 */
export function instanceOfSendSmsAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payload" in value;
    isInstance = isInstance && "messageType" in value;

    return isInstance;
}

export function SendSmsActionFromJSON(json: any): SendSmsAction {
    return SendSmsActionFromJSONTyped(json, false);
}

export function SendSmsActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendSmsAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'simCardFilter': !exists(json, 'simCardFilter') ? undefined : SimCardActionRequestFilterFromJSON(json['simCardFilter']),
        'payload': json['payload'],
        'messageType': MessageTypeFromJSON(json['messageType']),
        'msisdnSelection': !exists(json, 'msisdnSelection') ? undefined : (new Set((json['msisdnSelection'] as Array<any>).map(SendSmsActionMsisdnSelectionInnerFromJSON))),
    };
}

export function SendSmsActionToJSON(value?: SendSmsAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'simCardFilter': SimCardActionRequestFilterToJSON(value.simCardFilter),
        'payload': value.payload,
        'messageType': MessageTypeToJSON(value.messageType),
        'msisdnSelection': value.msisdnSelection === undefined ? undefined : (Array.from(value.msisdnSelection as Set<any>).map(SendSmsActionMsisdnSelectionInnerToJSON)),
    };
}

