/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible actions that can be performed in the CMP service.
 * @export
 */
export const ActionType = {
    Unknown: 'UNKNOWN',
    NetworkActivateSimCard: 'NETWORK_ACTIVATE_SIM_CARD',
    NetworkResetSimCard: 'NETWORK_RESET_SIM_CARD',
    NetworkSuspendSimCard: 'NETWORK_SUSPEND_SIM_CARD',
    NetworkManageUsagePackageAction: 'NETWORK_MANAGE_USAGE_PACKAGE_ACTION',
    NetworkSetImeiLockState: 'NETWORK_SET_IMEI_LOCK_STATE',
    NetworkUpdateImeiLock: 'NETWORK_UPDATE_IMEI_LOCK',
    NetworkManageSimCapabilityAction: 'NETWORK_MANAGE_SIM_CAPABILITY_ACTION'
} as const;
export type ActionType = typeof ActionType[keyof typeof ActionType];


export function ActionTypeFromJSON(json: any): ActionType {
    return ActionTypeFromJSONTyped(json, false);
}

export function ActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionType {
    return json as ActionType;
}

export function ActionTypeToJSON(value?: ActionType | null): any {
    return value as any;
}

