import { SimCard, SimCardSmsUsageSmsUsageInner } from "../../../../api";
import { Month, SmsUsageBarChart, SmsUsageEntry, SmsUsagePieChart, sum, UsageByPlmnEntry } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import React, { useMemo } from "react";
import { Box, TabPanel } from "@chakra-ui/react";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";

const mapToGraphableMonthSmsUsage = (
    values: SimCardSmsUsageSmsUsageInner[] | undefined,
    year: number,
    month: Month,
): UsageByPlmnEntry[] => {
    const mapToSmsUsageEntry = (value: SimCardSmsUsageSmsUsageInner): UsageByPlmnEntry[] => {
        const plmnAccumulation: { [plmn: string]: number } = {};

        value.imsis.forEach(
            imsiUsage =>
                imsiUsage.plmns?.forEach(plmnUsage => {
                    if (!plmnAccumulation[plmnUsage.plmn]) {
                        plmnAccumulation[plmnUsage.plmn] = 0;
                    }

                    plmnAccumulation[plmnUsage.plmn] += plmnUsage.total;
                }),
        );

        return Object.entries(plmnAccumulation).map(entry => ({ plmn: entry[0], value: entry[1] }));
    };
    if (values) {
        const matchingYearMonth = values.find(value => value.year === year && value.month === month);
        return matchingYearMonth ? mapToSmsUsageEntry(matchingYearMonth) : [];
    }
    return [];
};

const mapToGraphableTotalSmsUsage = (values: SimCardSmsUsageSmsUsageInner[] | undefined): SmsUsageEntry[] => {
    const mapToSmsUsageEntry = (value: SimCardSmsUsageSmsUsageInner): SmsUsageEntry => {
        const totalMobileOriginated =
            value.imsis
                .map(
                    imsiUsage =>
                        imsiUsage.plmns
                            ?.filter(plmnUsage => plmnUsage.direction === "MOBILE_ORIGINATED")
                            ?.map(plmnUsage => plmnUsage.total)
                            .reduce(sum) || 0,
                )
                .reduce(sum) || 0;
        const totalMobileTerminated =
            value.imsis
                .map(
                    imsiUsage =>
                        imsiUsage.plmns
                            ?.filter(plmnUsage => plmnUsage.direction === "MOBILE_TERMINATED")
                            ?.map(plmnUsage => plmnUsage.total)
                            .reduce(sum) || 0,
                )
                .reduce(sum) || 0;

        return {
            year: value.year,
            month: Month[value.month],
            mobileTerminatedMessages: totalMobileTerminated,
            mobileOriginatedMessages: totalMobileOriginated,
        };
    };
    return values ? values.map(mapToSmsUsageEntry) : [];
};

export interface SimCardSmsUsagePanelProps {
    simCard?: SimCard;
    showAllUsage?: boolean;
    selectedUsageYear: number | null;
    selectedUsageMonth: Month | null;
}

const SimCardSmsUsagePanel = ({
    simCard,
    selectedUsageMonth,
    selectedUsageYear,
    showAllUsage,
}: SimCardSmsUsagePanelProps) => {
    const { simCardApi } = useApi();
    const { data: simCardSmsUsage } = useQuery(
        ["simcards", simCard?.icc, "smsusage"],
        () => simCardApi.getSimCardSmsUsage({ icc: simCard?.icc || "" }).then(response => response.smsUsage),
        { enabled: simCard?.icc !== undefined },
    );

    const simCardTotalSmsUsage = useMemo(() => mapToGraphableTotalSmsUsage(simCardSmsUsage), [simCardSmsUsage]);

    const simCardMonthSmsUsage = useMemo(() => {
        if (selectedUsageMonth && selectedUsageYear) {
            return mapToGraphableMonthSmsUsage(simCardSmsUsage, selectedUsageYear, selectedUsageMonth);
        }
        return [];
    }, [simCardSmsUsage, selectedUsageYear, selectedUsageMonth]);

    return (
        <TabPanel>
            {showAllUsage && (
                <Box w={"100%"} h={"400px"}>
                    <SmsUsageBarChart data={simCardTotalSmsUsage} />
                </Box>
            )}
            {!showAllUsage && selectedUsageYear && selectedUsageMonth && (
                <Box w={"100%"} h={"400px"}>
                    <SmsUsagePieChart
                        year={selectedUsageYear}
                        month={selectedUsageMonth}
                        data={simCardMonthSmsUsage}
                        legend={plmn => <NetworkInfo plmn={plmn} />}
                    />
                </Box>
            )}
        </TabPanel>
    );
};

export default SimCardSmsUsagePanel;
