/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { SimCardActionItem } from './SimCardActionItem';
import {
    SimCardActionItemFromJSON,
    SimCardActionItemFromJSONTyped,
    SimCardActionItemToJSON,
} from './SimCardActionItem';

/**
 * 
 * @export
 * @interface SimCardActionItemPage
 */
export interface SimCardActionItemPage {
    /**
     * 
     * @type {Page}
     * @memberof SimCardActionItemPage
     */
    pageProperties?: Page;
    /**
     * 
     * @type {Array<SimCardActionItem>}
     * @memberof SimCardActionItemPage
     */
    content?: Array<SimCardActionItem>;
}

/**
 * Check if a given object implements the SimCardActionItemPage interface.
 */
export function instanceOfSimCardActionItemPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardActionItemPageFromJSON(json: any): SimCardActionItemPage {
    return SimCardActionItemPageFromJSONTyped(json, false);
}

export function SimCardActionItemPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardActionItemPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': !exists(json, 'pageProperties') ? undefined : PageFromJSON(json['pageProperties']),
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(SimCardActionItemFromJSON)),
    };
}

export function SimCardActionItemPageToJSON(value?: SimCardActionItemPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(SimCardActionItemToJSON)),
    };
}

