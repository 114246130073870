/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        CreateLabel,
        DataSessionPage,
        ESim,
        ESimPage,
        Hardware,
        Labels,
        LocationUpdatePage,
        ManageUsagePackage,
        NetworkCredential,
        NetworkState,
        NetworkStateRequest,
        SendSmsAction,
        SetImeiLockStateAction,
        SimCard,
        SimCardActionItemPage,
        SimCardDataUsage,
        SimCardNetworkState,
        SimCardPage,
        SimCardSmsUsage,
        SimConfiguration,
        UpdateImeiLockEntry,
        UpdateSimCard,
        UsagePackage,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            CreateLabelFromJSON,
            CreateLabelToJSON,
            DataSessionPageFromJSON,
            DataSessionPageToJSON,
            ESimFromJSON,
            ESimToJSON,
            ESimPageFromJSON,
            ESimPageToJSON,
            HardwareFromJSON,
            HardwareToJSON,
            LabelsFromJSON,
            LabelsToJSON,
            LocationUpdatePageFromJSON,
            LocationUpdatePageToJSON,
            ManageUsagePackageFromJSON,
            ManageUsagePackageToJSON,
            NetworkCredentialFromJSON,
            NetworkCredentialToJSON,
            NetworkStateFromJSON,
            NetworkStateToJSON,
            NetworkStateRequestFromJSON,
            NetworkStateRequestToJSON,
            SendSmsActionFromJSON,
            SendSmsActionToJSON,
            SetImeiLockStateActionFromJSON,
            SetImeiLockStateActionToJSON,
            SimCardFromJSON,
            SimCardToJSON,
            SimCardActionItemPageFromJSON,
            SimCardActionItemPageToJSON,
            SimCardDataUsageFromJSON,
            SimCardDataUsageToJSON,
            SimCardNetworkStateFromJSON,
            SimCardNetworkStateToJSON,
            SimCardPageFromJSON,
            SimCardPageToJSON,
            SimCardSmsUsageFromJSON,
            SimCardSmsUsageToJSON,
            SimConfigurationFromJSON,
            SimConfigurationToJSON,
            UpdateImeiLockEntryFromJSON,
            UpdateImeiLockEntryToJSON,
            UpdateSimCardFromJSON,
            UpdateSimCardToJSON,
            UsagePackageFromJSON,
            UsagePackageToJSON,
        } from '../models';

            export interface SimCardApiChangeSimCardStateRequest {
                networkStateRequest: NetworkStateRequest;
            }

            export interface SimCardApiCreateSimCardLabelRequest {
                icc: string;
                createLabel: CreateLabel;
            }

            export interface SimCardApiDeleteSimCardLabelRequest {
                icc: string;
                key: string;
            }

            export interface SimCardApiGetAllEsimsRequest {
                pageNumber: number;
                pageSize: number;
            }

            export interface SimCardApiGetEsimRequest {
                esimId: string;
            }

            export interface SimCardApiGetSimCardActionsRequest {
                icc: string;
                pageNumber: number;
                pageSize: number;
            }

            export interface SimCardApiGetSimCardByIccRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardDataSessionsRequest {
                icc: string;
                pageNumber: number;
                pageSize: number;
                fromDate?: Date;
                toDate?: Date;
            }

            export interface SimCardApiGetSimCardDataUsageRequest {
                icc: string;
                fromYear?: number;
                fromMonth?: GetSimCardDataUsageFromMonthEnum;
                toYear?: number;
                toMonth?: GetSimCardDataUsageToMonthEnum;
            }

            export interface SimCardApiGetSimCardHardwareRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardLabelsRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardLocationUpdatesRequest {
                icc: string;
                pageNumber: number;
                pageSize: number;
                fromDate?: Date;
                toDate?: Date;
            }

            export interface SimCardApiGetSimCardNetworkCredentialsRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardNetworkStateRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardSmsUsageRequest {
                icc: string;
                fromYear?: number;
                fromMonth?: GetSimCardSmsUsageFromMonthEnum;
                toYear?: number;
                toMonth?: GetSimCardSmsUsageToMonthEnum;
            }

            export interface SimCardApiGetSimCardUsagePackagesRequest {
                icc: string;
            }

            export interface SimCardApiGetSimCardsRequest {
                pageNumber: number;
                pageSize: number;
                iccLowerBound?: string;
                iccUpperBound?: string;
                simConfigurationId?: string;
                billingGroupId?: string;
                search?: string;
                networkState?: NetworkState;
                esimId?: string;
                labels?: string | null;
            }

            export interface SimCardApiGetSimConfigurationRequest {
                simConfigurationId: string;
            }

            export interface SimCardApiManageSimCardUsagePackageRequest {
                manageUsagePackage: ManageUsagePackage;
            }

            export interface SimCardApiSendSmsToSimCardsRequest {
                sendSmsAction: SendSmsAction;
            }

            export interface SimCardApiSetImeiLockStatesRequest {
                setImeiLockStateAction: SetImeiLockStateAction;
            }

            export interface SimCardApiUpdateImeiLocksRequest {
                updateImeiLockEntry: Array<UpdateImeiLockEntry>;
            }

            export interface SimCardApiUpdateSimCardRequest {
                icc: string;
                updateSimCard: UpdateSimCard;
            }

        /**
        * 
        */
            export class SimCardApi extends runtime.BaseAPI {

            /**
                * Perform a state change request against the filtered list of sim cards. The operation will be executed against all associated network credentials.
                * Change sim card state
            */
            async changeSimCardStateRaw(requestParameters: SimCardApiChangeSimCardStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.networkStateRequest === null || requestParameters.networkStateRequest === undefined) {
                    throw new runtime.RequiredError('networkStateRequest','Required parameter requestParameters.networkStateRequest was null or undefined when calling changeSimCardState.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/changestate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: NetworkStateRequestToJSON(requestParameters.networkStateRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Perform a state change request against the filtered list of sim cards. The operation will be executed against all associated network credentials.
                * Change sim card state
            */
                async changeSimCardState(requestParameters: SimCardApiChangeSimCardStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.changeSimCardStateRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create new label and associate with SIM.
                * Create SIM Card Label
            */
            async createSimCardLabelRaw(requestParameters: SimCardApiCreateSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling createSimCardLabel.');
                    }

                    if (requestParameters.createLabel === null || requestParameters.createLabel === undefined) {
                    throw new runtime.RequiredError('createLabel','Required parameter requestParameters.createLabel was null or undefined when calling createSimCardLabel.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateLabelToJSON(requestParameters.createLabel),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Create new label and associate with SIM.
                * Create SIM Card Label
            */
                async createSimCardLabel(requestParameters: SimCardApiCreateSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.createSimCardLabelRaw(requestParameters, initOverrides);
                }

            /**
                * Delete label associated with SIM.
                * Delete SIM Card Label
            */
            async deleteSimCardLabelRaw(requestParameters: SimCardApiDeleteSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling deleteSimCardLabel.');
                    }

                    if (requestParameters.key === null || requestParameters.key === undefined) {
                    throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteSimCardLabel.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels/{key}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Delete label associated with SIM.
                * Delete SIM Card Label
            */
                async deleteSimCardLabel(requestParameters: SimCardApiDeleteSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteSimCardLabelRaw(requestParameters, initOverrides);
                }

            /**
                * Get all ESIMs
            */
            async getAllEsimsRaw(requestParameters: SimCardApiGetAllEsimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ESimPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getAllEsims.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAllEsims.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/esims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ESimPageFromJSON(jsonValue));
            }

            /**
                * Get all ESIMs
            */
                async getAllEsims(requestParameters: SimCardApiGetAllEsimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ESimPage> {
                    const response = await this.getAllEsimsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get ESIM
            */
            async getEsimRaw(requestParameters: SimCardApiGetEsimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ESim>> {
                    if (requestParameters.esimId === null || requestParameters.esimId === undefined) {
                    throw new runtime.RequiredError('esimId','Required parameter requestParameters.esimId was null or undefined when calling getEsim.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/esims/{esimId}`.replace(`{${"esimId"}}`, encodeURIComponent(String(requestParameters.esimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ESimFromJSON(jsonValue));
            }

            /**
                * Get ESIM
            */
                async getEsim(requestParameters: SimCardApiGetEsimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ESim> {
                    const response = await this.getEsimRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get actions that has been applied to the sim card.
                * Get actions applied to the sim card
            */
            async getSimCardActionsRaw(requestParameters: SimCardApiGetSimCardActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardActionItemPage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardActions.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCardActions.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCardActions.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/actions`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardActionItemPageFromJSON(jsonValue));
            }

            /**
                * Get actions that has been applied to the sim card.
                * Get actions applied to the sim card
            */
                async getSimCardActions(requestParameters: SimCardApiGetSimCardActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardActionItemPage> {
                    const response = await this.getSimCardActionsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get information for a particular SIM card.
                * Get a SIM card by ICC
            */
            async getSimCardByIccRaw(requestParameters: SimCardApiGetSimCardByIccRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCard>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardByIcc.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardFromJSON(jsonValue));
            }

            /**
                * Get information for a particular SIM card.
                * Get a SIM card by ICC
            */
                async getSimCardByIcc(requestParameters: SimCardApiGetSimCardByIccRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCard> {
                    const response = await this.getSimCardByIccRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get data sessions for a specific sim card. Data sessions are always accumulated and may continue to change while the session is not yet closed.
                * Get Data sessions for a SIM card
            */
            async getSimCardDataSessionsRaw(requestParameters: SimCardApiGetSimCardDataSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSessionPage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardDataSessions.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCardDataSessions.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCardDataSessions.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/datasessions`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DataSessionPageFromJSON(jsonValue));
            }

            /**
                * Get data sessions for a specific sim card. Data sessions are always accumulated and may continue to change while the session is not yet closed.
                * Get Data sessions for a SIM card
            */
                async getSimCardDataSessions(requestParameters: SimCardApiGetSimCardDataSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSessionPage> {
                    const response = await this.getSimCardDataSessionsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get data usage for a sim card. This endpoint is grouped by year, month, IMSI, and network, for each sim card.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Usage for a SIM card in a (optional) year / month period
            */
            async getSimCardDataUsageRaw(requestParameters: SimCardApiGetSimCardDataUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardDataUsage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardDataUsage.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.fromYear !== undefined) {
                            queryParameters['fromYear'] = requestParameters.fromYear;
                    }

                    if (requestParameters.fromMonth !== undefined) {
                            queryParameters['fromMonth'] = requestParameters.fromMonth;
                    }

                    if (requestParameters.toYear !== undefined) {
                            queryParameters['toYear'] = requestParameters.toYear;
                    }

                    if (requestParameters.toMonth !== undefined) {
                            queryParameters['toMonth'] = requestParameters.toMonth;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/usage/data`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardDataUsageFromJSON(jsonValue));
            }

            /**
                * Get data usage for a sim card. This endpoint is grouped by year, month, IMSI, and network, for each sim card.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Usage for a SIM card in a (optional) year / month period
            */
                async getSimCardDataUsage(requestParameters: SimCardApiGetSimCardDataUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardDataUsage> {
                    const response = await this.getSimCardDataUsageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch hardware information for the device the SIM Card is inserted into.  This information is available only for SIM Cards with a configuration that supports `HARDWARE_REGISTRATION`.
                * Get SIM Card Hardware information
            */
            async getSimCardHardwareRaw(requestParameters: SimCardApiGetSimCardHardwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Hardware>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardHardware.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/hardware`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => HardwareFromJSON(jsonValue));
            }

            /**
                * Fetch hardware information for the device the SIM Card is inserted into.  This information is available only for SIM Cards with a configuration that supports `HARDWARE_REGISTRATION`.
                * Get SIM Card Hardware information
            */
                async getSimCardHardware(requestParameters: SimCardApiGetSimCardHardwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Hardware> {
                    const response = await this.getSimCardHardwareRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get labels associated with SIM.
                * Get SIM Card Labels
            */
            async getSimCardLabelsRaw(requestParameters: SimCardApiGetSimCardLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Labels>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardLabels.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => LabelsFromJSON(jsonValue));
            }

            /**
                * Get labels associated with SIM.
                * Get SIM Card Labels
            */
                async getSimCardLabels(requestParameters: SimCardApiGetSimCardLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Labels> {
                    const response = await this.getSimCardLabelsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get location updates for a specific sim card.
                * Get Location updates for a SIM card
            */
            async getSimCardLocationUpdatesRaw(requestParameters: SimCardApiGetSimCardLocationUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationUpdatePage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardLocationUpdates.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCardLocationUpdates.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCardLocationUpdates.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/locationupdates`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => LocationUpdatePageFromJSON(jsonValue));
            }

            /**
                * Get location updates for a specific sim card.
                * Get Location updates for a SIM card
            */
                async getSimCardLocationUpdates(requestParameters: SimCardApiGetSimCardLocationUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationUpdatePage> {
                    const response = await this.getSimCardLocationUpdatesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all currently configured network credentials for the selected SIM card.
                * Get all network credentials configured for the SIM card.
            */
            async getSimCardNetworkCredentialsRaw(requestParameters: SimCardApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NetworkCredential>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardNetworkCredentials.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkCredentialFromJSON));
            }

            /**
                * Get all currently configured network credentials for the selected SIM card.
                * Get all network credentials configured for the SIM card.
            */
                async getSimCardNetworkCredentials(requestParameters: SimCardApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NetworkCredential>> {
                    const response = await this.getSimCardNetworkCredentialsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get network state for the SIM card.  The network state represents the latest recorded information across all network credentials for the SIM card.
                * Get SIM card network state
            */
            async getSimCardNetworkStateRaw(requestParameters: SimCardApiGetSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardNetworkState>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardNetworkState.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkstate`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardNetworkStateFromJSON(jsonValue));
            }

            /**
                * Get network state for the SIM card.  The network state represents the latest recorded information across all network credentials for the SIM card.
                * Get SIM card network state
            */
                async getSimCardNetworkState(requestParameters: SimCardApiGetSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardNetworkState> {
                    const response = await this.getSimCardNetworkStateRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get SMS usage for a sim card. This endpoint is grouped by year, month, IMSI, and network, for each sim card.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get SIM card SMS usage
            */
            async getSimCardSmsUsageRaw(requestParameters: SimCardApiGetSimCardSmsUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardSmsUsage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardSmsUsage.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.fromYear !== undefined) {
                            queryParameters['fromYear'] = requestParameters.fromYear;
                    }

                    if (requestParameters.fromMonth !== undefined) {
                            queryParameters['fromMonth'] = requestParameters.fromMonth;
                    }

                    if (requestParameters.toYear !== undefined) {
                            queryParameters['toYear'] = requestParameters.toYear;
                    }

                    if (requestParameters.toMonth !== undefined) {
                            queryParameters['toMonth'] = requestParameters.toMonth;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/usage/sms`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardSmsUsageFromJSON(jsonValue));
            }

            /**
                * Get SMS usage for a sim card. This endpoint is grouped by year, month, IMSI, and network, for each sim card.  If you do not specify any `fromYear`/`fromMonth`, the beginning of recorded time will be used.  If you do not specify any bounds, the entire available data set will be available.
                * Get SIM card SMS usage
            */
                async getSimCardSmsUsage(requestParameters: SimCardApiGetSimCardSmsUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardSmsUsage> {
                    const response = await this.getSimCardSmsUsageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch assigned usage package to for a SIM card
                * Get usage packages for SIM card
            */
            async getSimCardUsagePackagesRaw(requestParameters: SimCardApiGetSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UsagePackage>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardUsagePackages.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/usagepackages`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsagePackageFromJSON));
            }

            /**
                * Fetch assigned usage package to for a SIM card
                * Get usage packages for SIM card
            */
                async getSimCardUsagePackages(requestParameters: SimCardApiGetSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UsagePackage>> {
                    const response = await this.getSimCardUsagePackagesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get page of SIM cards associated with your account. When using the ICC bound parameters the following will be applied: If lower bound is applied and no upper bound then it will be all ICC values that are \'>=\' than the lower bound. Similarly for the upper bound if no lower bound is specified, it is all the ICC values that are \'<=\' than the lower bound.  If both are specified it is all the sim cards where the ICC is between the bounds, bounds included.
                * Get a page of SIM cards
            */
            async getSimCardsRaw(requestParameters: SimCardApiGetSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCards.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCards.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.iccLowerBound !== undefined) {
                            queryParameters['iccLowerBound'] = requestParameters.iccLowerBound;
                    }

                    if (requestParameters.iccUpperBound !== undefined) {
                            queryParameters['iccUpperBound'] = requestParameters.iccUpperBound;
                    }

                    if (requestParameters.simConfigurationId !== undefined) {
                            queryParameters['simConfigurationId'] = requestParameters.simConfigurationId;
                    }

                    if (requestParameters.billingGroupId !== undefined) {
                            queryParameters['billingGroupId'] = requestParameters.billingGroupId;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.networkState !== undefined) {
                            queryParameters['networkState'] = requestParameters.networkState;
                    }

                    if (requestParameters.esimId !== undefined) {
                            queryParameters['esimId'] = requestParameters.esimId;
                    }

                    if (requestParameters.labels !== undefined) {
                            queryParameters['labels'] = requestParameters.labels;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardPageFromJSON(jsonValue));
            }

            /**
                * Get page of SIM cards associated with your account. When using the ICC bound parameters the following will be applied: If lower bound is applied and no upper bound then it will be all ICC values that are \'>=\' than the lower bound. Similarly for the upper bound if no lower bound is specified, it is all the ICC values that are \'<=\' than the lower bound.  If both are specified it is all the sim cards where the ICC is between the bounds, bounds included.
                * Get a page of SIM cards
            */
                async getSimCards(requestParameters: SimCardApiGetSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardPage> {
                    const response = await this.getSimCardsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single SIM configuration by its id.
                * Get SIM Configuration
            */
            async getSimConfigurationRaw(requestParameters: SimCardApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfiguration>> {
                    if (requestParameters.simConfigurationId === null || requestParameters.simConfigurationId === undefined) {
                    throw new runtime.RequiredError('simConfigurationId','Required parameter requestParameters.simConfigurationId was null or undefined when calling getSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations/{simConfigurationId}`.replace(`{${"simConfigurationId"}}`, encodeURIComponent(String(requestParameters.simConfigurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationFromJSON(jsonValue));
            }

            /**
                * Get a single SIM configuration by its id.
                * Get SIM Configuration
            */
                async getSimConfiguration(requestParameters: SimCardApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfiguration> {
                    const response = await this.getSimConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all SIM configurations that are available to your account. The actual SIM configuration that is applied to a SIM card can be seen in the `simConfigurationId` property for the SIM card model.
                * Get all SIM configurations
            */
            async getSimConfigurationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimConfiguration>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimConfigurationFromJSON));
            }

            /**
                * Get all SIM configurations that are available to your account. The actual SIM configuration that is applied to a SIM card can be seen in the `simConfigurationId` property for the SIM card model.
                * Get all SIM configurations
            */
                async getSimConfigurations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimConfiguration>> {
                    const response = await this.getSimConfigurationsRaw(initOverrides);
                    return await response.value();
                }

            /**
                * Manage a SIM cards usage package
                * Manage SIM card Usage package
            */
            async manageSimCardUsagePackageRaw(requestParameters: SimCardApiManageSimCardUsagePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.manageUsagePackage === null || requestParameters.manageUsagePackage === undefined) {
                    throw new runtime.RequiredError('manageUsagePackage','Required parameter requestParameters.manageUsagePackage was null or undefined when calling manageSimCardUsagePackage.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/manageusagepackages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: ManageUsagePackageToJSON(requestParameters.manageUsagePackage),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Manage a SIM cards usage package
                * Manage SIM card Usage package
            */
                async manageSimCardUsagePackage(requestParameters: SimCardApiManageSimCardUsagePackageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.manageSimCardUsagePackageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Attempt to send an SMS to all the SIM Cards found in the filter. The selected MSISDN for each ICC can be specified, but if not specified, an appropriate MSISDN will be selected if any are available.
                * Send an SMS to the SIM Cards
            */
            async sendSmsToSimCardsRaw(requestParameters: SimCardApiSendSmsToSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.sendSmsAction === null || requestParameters.sendSmsAction === undefined) {
                    throw new runtime.RequiredError('sendSmsAction','Required parameter requestParameters.sendSmsAction was null or undefined when calling sendSmsToSimCards.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/sendsms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: SendSmsActionToJSON(requestParameters.sendSmsAction),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Attempt to send an SMS to all the SIM Cards found in the filter. The selected MSISDN for each ICC can be specified, but if not specified, an appropriate MSISDN will be selected if any are available.
                * Send an SMS to the SIM Cards
            */
                async sendSmsToSimCards(requestParameters: SimCardApiSendSmsToSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.sendSmsToSimCardsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Sets the IMEI lock state for the selected SIM cards.  This will only control the IMEI lock violation behaviour and state, if you need to insert specific IMEIs for SIM cards please use the `/actions/simcards/updateimeilock` endpoint.
                * Set IMEI lock state
            */
            async setImeiLockStatesRaw(requestParameters: SimCardApiSetImeiLockStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.setImeiLockStateAction === null || requestParameters.setImeiLockStateAction === undefined) {
                    throw new runtime.RequiredError('setImeiLockStateAction','Required parameter requestParameters.setImeiLockStateAction was null or undefined when calling setImeiLockStates.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/setimeilockstates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: SetImeiLockStateActionToJSON(requestParameters.setImeiLockStateAction),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Sets the IMEI lock state for the selected SIM cards.  This will only control the IMEI lock violation behaviour and state, if you need to insert specific IMEIs for SIM cards please use the `/actions/simcards/updateimeilock` endpoint.
                * Set IMEI lock state
            */
                async setImeiLockStates(requestParameters: SimCardApiSetImeiLockStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.setImeiLockStatesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create or delete IMEI lock entries for a set of selected SIM cards.  The added IMEI will not be enforced unless the IMEI lock state is set to either `CHECK` or `CHECK_OR_SET`. This is done using the endpoint `/actions/simcards/setimeilockstate`.
                * Update IMEI locks
            */
            async updateImeiLocksRaw(requestParameters: SimCardApiUpdateImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.updateImeiLockEntry === null || requestParameters.updateImeiLockEntry === undefined) {
                    throw new runtime.RequiredError('updateImeiLockEntry','Required parameter requestParameters.updateImeiLockEntry was null or undefined when calling updateImeiLocks.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/updateimeilocks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                            body: requestParameters.updateImeiLockEntry.map(UpdateImeiLockEntryToJSON),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Create or delete IMEI lock entries for a set of selected SIM cards.  The added IMEI will not be enforced unless the IMEI lock state is set to either `CHECK` or `CHECK_OR_SET`. This is done using the endpoint `/actions/simcards/setimeilockstate`.
                * Update IMEI locks
            */
                async updateImeiLocks(requestParameters: SimCardApiUpdateImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.updateImeiLocksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Updates the `customName` property of a sim card.
                * Update SimCard
            */
            async updateSimCardRaw(requestParameters: SimCardApiUpdateSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCard>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling updateSimCard.');
                    }

                    if (requestParameters.updateSimCard === null || requestParameters.updateSimCard === undefined) {
                    throw new runtime.RequiredError('updateSimCard','Required parameter requestParameters.updateSimCard was null or undefined when calling updateSimCard.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateSimCardToJSON(requestParameters.updateSimCard),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardFromJSON(jsonValue));
            }

            /**
                * Updates the `customName` property of a sim card.
                * Update SimCard
            */
                async updateSimCard(requestParameters: SimCardApiUpdateSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCard> {
                    const response = await this.updateSimCardRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }

                            /**
                            * @export
                            */
                            export const GetSimCardDataUsageFromMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetSimCardDataUsageFromMonthEnum = typeof GetSimCardDataUsageFromMonthEnum[keyof typeof GetSimCardDataUsageFromMonthEnum];
                            /**
                            * @export
                            */
                            export const GetSimCardDataUsageToMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetSimCardDataUsageToMonthEnum = typeof GetSimCardDataUsageToMonthEnum[keyof typeof GetSimCardDataUsageToMonthEnum];
                            /**
                            * @export
                            */
                            export const GetSimCardSmsUsageFromMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetSimCardSmsUsageFromMonthEnum = typeof GetSimCardSmsUsageFromMonthEnum[keyof typeof GetSimCardSmsUsageFromMonthEnum];
                            /**
                            * @export
                            */
                            export const GetSimCardSmsUsageToMonthEnum = {
                                January: 'JANUARY',
                                February: 'FEBRUARY',
                                March: 'MARCH',
                                April: 'APRIL',
                                May: 'MAY',
                                June: 'JUNE',
                                July: 'JULY',
                                August: 'AUGUST',
                                September: 'SEPTEMBER',
                                October: 'OCTOBER',
                                November: 'NOVEMBER',
                                December: 'DECEMBER'
                            } as const;
                            export type GetSimCardSmsUsageToMonthEnum = typeof GetSimCardSmsUsageToMonthEnum[keyof typeof GetSimCardSmsUsageToMonthEnum];
