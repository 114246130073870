import React, { useMemo } from "react";
import { DataText, LoadingSkeleton, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import DateUtils from "../../../../utils/dateUtils";

export interface BillingGroupDataUsageCell {
    billingGroupId: string;
    period: "current-year" | "current-month" | "last-month";
}

export const BillingGroupDataUsageCell = ({ billingGroupId, period }: BillingGroupDataUsageCell) => {
    const { billingGroupApi } = useApi();

    const dateInterval = useMemo(() => {
        if (period === "current-year") {
            const thisYearInterval = DateUtils.thisYearInterval();
            return {
                fromMonth: thisYearInterval.from.month,
                toMonth: thisYearInterval.to.month,
                fromYear: thisYearInterval.from.year,
                toYear: thisYearInterval.to.year,
            };
        } else if (period === "current-month") {
            const currentMonthInterval = DateUtils.currentMonthInterval();
            return {
                fromMonth: currentMonthInterval.from.month,
                toMonth: currentMonthInterval.to.month,
                fromYear: currentMonthInterval.from.year,
                toYear: currentMonthInterval.to.year,
            };
        } else {
            const lastMonthInterval = DateUtils.lastMonthInterval();
            return {
                fromMonth: lastMonthInterval.from.month,
                toMonth: lastMonthInterval.to.month,
                fromYear: lastMonthInterval.from.year,
                toYear: lastMonthInterval.to.year,
            };
        }
    }, [period]);

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    billingGroupApi.getBillingGroupDataUsage({
                        billingGroupId: billingGroupId,
                        fromMonth: dateInterval.fromMonth,
                        toMonth: dateInterval.toMonth,
                        fromYear: dateInterval.fromYear,
                        toYear: dateInterval.toYear,
                    }),
                queryKey: ["billing-groups", billingGroupId, "data-usage", period],
            }}
            render={result => <DataText bytes={result?.data?.totalBytes} decimals={2} />}
        />
    );
};
