/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAccessToken
 */
export interface UpdateAccessToken {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessToken
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessToken
     */
    description?: string | null;
}

/**
 * Check if a given object implements the UpdateAccessToken interface.
 */
export function instanceOfUpdateAccessToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UpdateAccessTokenFromJSON(json: any): UpdateAccessToken {
    return UpdateAccessTokenFromJSONTyped(json, false);
}

export function UpdateAccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccessToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdateAccessTokenToJSON(value?: UpdateAccessToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

