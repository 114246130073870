/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SmsDirection } from './SmsDirection';
import {
    SmsDirectionFromJSON,
    SmsDirectionFromJSONTyped,
    SmsDirectionToJSON,
} from './SmsDirection';

/**
 * SMS usage by grouped by the PLMN of usage entry
 * @export
 * @interface SmsUsageByPlmn
 */
export interface SmsUsageByPlmn {
    /**
     * 
     * @type {string}
     * @memberof SmsUsageByPlmn
     */
    plmn: string;
    /**
     * 
     * @type {number}
     * @memberof SmsUsageByPlmn
     */
    total: number;
    /**
     * 
     * @type {SmsDirection}
     * @memberof SmsUsageByPlmn
     */
    direction: SmsDirection;
    /**
     * 
     * @type {string}
     * @memberof SmsUsageByPlmn
     */
    country: string;
}

/**
 * Check if a given object implements the SmsUsageByPlmn interface.
 */
export function instanceOfSmsUsageByPlmn(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "direction" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function SmsUsageByPlmnFromJSON(json: any): SmsUsageByPlmn {
    return SmsUsageByPlmnFromJSONTyped(json, false);
}

export function SmsUsageByPlmnFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsUsageByPlmn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plmn': json['plmn'],
        'total': json['total'],
        'direction': SmsDirectionFromJSON(json['direction']),
        'country': json['country'],
    };
}

export function SmsUsageByPlmnToJSON(value?: SmsUsageByPlmn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plmn': value.plmn,
        'total': value.total,
        'direction': SmsDirectionToJSON(value.direction),
        'country': value.country,
    };
}

