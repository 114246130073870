/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Radio technologies covering 2G to 5G.
 * @export
 */
export const RadioTechnology = {
    _2G: '2G',
    _3G: '3G',
    _4G: '4G',
    _5G: '5G'
} as const;
export type RadioTechnology = typeof RadioTechnology[keyof typeof RadioTechnology];


export function RadioTechnologyFromJSON(json: any): RadioTechnology {
    return RadioTechnologyFromJSONTyped(json, false);
}

export function RadioTechnologyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RadioTechnology {
    return json as RadioTechnology;
}

export function RadioTechnologyToJSON(value?: RadioTechnology | null): any {
    return value as any;
}

