/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimCardNetworkStateImei
 */
export interface SimCardNetworkStateImei {
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateImei
     */
    imei?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateImei
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateImei interface.
 */
export function instanceOfSimCardNetworkStateImei(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateImeiFromJSON(json: any): SimCardNetworkStateImei {
    return SimCardNetworkStateImeiFromJSONTyped(json, false);
}

export function SimCardNetworkStateImeiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateImei {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateImeiToJSON(value?: SimCardNetworkStateImei | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imei': value.imei,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

