import {
    ActiveSimCardsIcon,
    IdleSimCardsIcon,
    InfoCard,
    InfoCards,
    SimCardIcon,
    SuspendedSimCardsIcon,
} from "@cobira/ui-library";
import React from "react";
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BillingGroup, NetworkState } from "../../../api";
import { useSimCardCount } from "../../../hooks/useSimCardCount";
import useSimCardSearch from "../../../hooks/useSimCardSearch";

export interface BillingGroupSimCardCountInfoCardsProps {
    billingGroup?: BillingGroup;
    isLoading?: boolean;
}

const BillingGroupSimCardCountInfoCards = ({ billingGroup, isLoading }: BillingGroupSimCardCountInfoCardsProps) => {
    const activeSimCards = useSimCardCount({
        isLoading: isLoading,
        filter: { networkState: "ACTIVE", billingGroupId: billingGroup?.id },
    });
    const suspendedSimCards = useSimCardCount({
        isLoading: isLoading,
        filter: { networkState: "SUSPENDED", billingGroupId: billingGroup?.id },
    });
    const idleSimCards = useSimCardCount({
        isLoading: isLoading,
        filter: { networkState: "IDLE", billingGroupId: billingGroup?.id },
    });
    const totalSimCards = (activeSimCards || 0) + (suspendedSimCards || 0) + (idleSimCards || 0);
    const search = useSimCardSearch({});
    const setBillingGroupSimCardFilter = (simCardState?: NetworkState) => () => {
        search.setState({
            simCardState: {
                value: simCardState,
            },
            billingGroup: {
                value: billingGroup,
            },
        });
    };

    return (
        <InfoCards>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setBillingGroupSimCardFilter()}>
                    <InfoCard
                        title={"Total SIM Cards"}
                        value={`${totalSimCards}`}
                        icon={SimCardIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setBillingGroupSimCardFilter("ACTIVE")}>
                    <InfoCard
                        title={"Activated SIM Cards"}
                        value={`${activeSimCards}`}
                        icon={ActiveSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setBillingGroupSimCardFilter("SUSPENDED")}>
                    <InfoCard
                        title={"Suspended SIM Cards"}
                        value={`${suspendedSimCards}`}
                        icon={SuspendedSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
            <LinkBox>
                <LinkOverlay as={Link} to={"/simcards"} onClick={setBillingGroupSimCardFilter("IDLE")}>
                    <InfoCard
                        title={"Idle SIM Cards"}
                        value={`${idleSimCards}`}
                        icon={IdleSimCardsIcon}
                        color={"primary"}
                    />
                </LinkOverlay>
            </LinkBox>
        </InfoCards>
    );
};

export default BillingGroupSimCardCountInfoCards;
