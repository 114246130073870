/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';
import type { SimCardDataUsageDataUsageInnerImsisInner } from './SimCardDataUsageDataUsageInnerImsisInner';
import {
    SimCardDataUsageDataUsageInnerImsisInnerFromJSON,
    SimCardDataUsageDataUsageInnerImsisInnerFromJSONTyped,
    SimCardDataUsageDataUsageInnerImsisInnerToJSON,
} from './SimCardDataUsageDataUsageInnerImsisInner';

/**
 * 
 * @export
 * @interface SimCardDataUsageDataUsageInner
 */
export interface SimCardDataUsageDataUsageInner {
    /**
     * A specific year between 2020 and 9999.
     * @type {number}
     * @memberof SimCardDataUsageDataUsageInner
     */
    year: number;
    /**
     * 
     * @type {Month}
     * @memberof SimCardDataUsageDataUsageInner
     */
    month: Month;
    /**
     * 
     * @type {Array<SimCardDataUsageDataUsageInnerImsisInner>}
     * @memberof SimCardDataUsageDataUsageInner
     */
    imsis: Array<SimCardDataUsageDataUsageInnerImsisInner>;
}

/**
 * Check if a given object implements the SimCardDataUsageDataUsageInner interface.
 */
export function instanceOfSimCardDataUsageDataUsageInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "imsis" in value;

    return isInstance;
}

export function SimCardDataUsageDataUsageInnerFromJSON(json: any): SimCardDataUsageDataUsageInner {
    return SimCardDataUsageDataUsageInnerFromJSONTyped(json, false);
}

export function SimCardDataUsageDataUsageInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardDataUsageDataUsageInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'month': MonthFromJSON(json['month']),
        'imsis': ((json['imsis'] as Array<any>).map(SimCardDataUsageDataUsageInnerImsisInnerFromJSON)),
    };
}

export function SimCardDataUsageDataUsageInnerToJSON(value?: SimCardDataUsageDataUsageInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'month': MonthToJSON(value.month),
        'imsis': ((value.imsis as Array<any>).map(SimCardDataUsageDataUsageInnerImsisInnerToJSON)),
    };
}

