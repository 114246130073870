import {
    AccessTokenApi,
    ActionApi,
    BaseAPI,
    BillingGroupApi,
    Configuration,
    IntegrationApi,
    NetworkApi,
    NetworkCredentialApi,
    RoleApi,
    SimCardApi,
    UsagePackageApi,
    UserApi,
    WebhookApi,
} from "../api";
import { useCobiraEnvironment } from "@cobira/ui-library";
import { useAuth0 } from "@auth0/auth0-react";
import * as runtime from "../api/runtime";

class DocumentationApi extends BaseAPI {
    async downloadWebhookSchemas(): Promise<Blob> {
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/zip";

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook-schemas.zip`,
            method: "GET",
            headers: headerParameters,
        });

        return response.blob();
    }

    async downloadApiDocumentation(): Promise<Blob> {
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/text";
        const response = await this.request({
            path: `/openapi.yaml`,
            method: "GET",
            headers: headerParameters,
        });

        return response.blob();
    }
}

export const useApi = () => {
    const [, environment] = useCobiraEnvironment();
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const apiConfiguration = new Configuration({
        accessToken: getAccessTokenSilently()
            .then(value => value)
            .catch(() => {
                loginWithRedirect();
                return "";
            }),
        basePath: environment.apiUrl,
    });

    return {
        actionApi: new ActionApi(apiConfiguration),
        simCardApi: new SimCardApi(apiConfiguration),
        billingGroupApi: new BillingGroupApi(apiConfiguration),
        networkApi: new NetworkApi(apiConfiguration),
        usagePackageApi: new UsagePackageApi(apiConfiguration),
        userApi: new UserApi(apiConfiguration),
        integrationApi: new IntegrationApi(apiConfiguration),
        networkCredentialApi: new NetworkCredentialApi(apiConfiguration),
        webhookApi: new WebhookApi(apiConfiguration),
        roleApi: new RoleApi(apiConfiguration),
        accessTokenApi: new AccessTokenApi(apiConfiguration),
        documentationApi: new DocumentationApi(apiConfiguration),
    };
};
