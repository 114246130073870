/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardCapabilityName } from './SimCardCapabilityName';
import {
    SimCardCapabilityNameFromJSON,
    SimCardCapabilityNameFromJSONTyped,
    SimCardCapabilityNameToJSON,
} from './SimCardCapabilityName';

/**
 * Representation of a SIM Card capability.
 * @export
 * @interface SimCardCapability
 */
export interface SimCardCapability {
    /**
     * 
     * @type {SimCardCapabilityName}
     * @memberof SimCardCapability
     */
    name: SimCardCapabilityName;
}

/**
 * Check if a given object implements the SimCardCapability interface.
 */
export function instanceOfSimCardCapability(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SimCardCapabilityFromJSON(json: any): SimCardCapability {
    return SimCardCapabilityFromJSONTyped(json, false);
}

export function SimCardCapabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardCapability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': SimCardCapabilityNameFromJSON(json['name']),
    };
}

export function SimCardCapabilityToJSON(value?: SimCardCapability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': SimCardCapabilityNameToJSON(value.name),
    };
}

