import { Grid } from "@chakra-ui/react";
import {
    currentMonthInterval,
    DataText,
    lastMonthInterval,
    Paper,
    TextColumn,
    thisYearInterval,
} from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { BillingGroup } from "../../../api";
import { useApi } from "../../../hooks/useApi";

export interface BillingGroupDataUsagePaperProps {
    billingGroup?: BillingGroup;
    isLoading?: boolean;
}

const BillingGroupDataUsagePaper = ({ billingGroup, isLoading }: BillingGroupDataUsagePaperProps) => {
    const { billingGroupApi } = useApi();
    const thisYear = thisYearInterval();
    const currentMonth = currentMonthInterval();
    const lastMonth = lastMonthInterval();

    const currentMonthDataUsage = useQuery(
        ["billinggroups", billingGroup?.id, "data", currentMonth],
        () =>
            billingGroupApi.getBillingGroupDataUsage({
                billingGroupId: billingGroup?.id || "",
                fromMonth: currentMonth.from.month,
                toMonth: currentMonth.to.month,
                fromYear: currentMonth.from.year,
                toYear: currentMonth.to.year,
            }),
        {
            enabled: billingGroup?.id != undefined,
        },
    );

    const lastMonthDataUsage = useQuery(
        ["billinggroups", billingGroup?.id, "data", lastMonth],
        () =>
            billingGroupApi.getBillingGroupDataUsage({
                billingGroupId: billingGroup?.id || "",
                fromMonth: lastMonth.from.month,
                toMonth: lastMonth.to.month,
                fromYear: lastMonth.from.year,
                toYear: lastMonth.to.year,
            }),
        {
            enabled: billingGroup?.id != undefined,
        },
    );

    const thisYearDataUsage = useQuery(
        ["billinggroups", billingGroup?.id, "data", thisYear],
        () =>
            billingGroupApi.getBillingGroupDataUsage({
                billingGroupId: billingGroup?.id || "",
                fromMonth: thisYear.from.month,
                toMonth: thisYear.to.month,
                fromYear: thisYear.from.year,
                toYear: thisYear.to.year,
            }),
        {
            enabled: billingGroup?.id != undefined,
        },
    );

    return (
        <Paper header={"Data Usage"} withDivider>
            <Grid templateColumns="auto auto auto" gap="4">
                <TextColumn heading="Current Year" grid isLoading={isLoading || thisYearDataUsage.isLoading}>
                    <DataText bytes={thisYearDataUsage?.data?.totalBillableBytes} decimals={2} />
                </TextColumn>
                <TextColumn heading="Last Month" grid isLoading={isLoading || lastMonthDataUsage.isLoading}>
                    <DataText bytes={lastMonthDataUsage?.data?.totalBillableBytes} decimals={2} />
                </TextColumn>
                <TextColumn heading="Current Month" grid isLoading={isLoading || currentMonthDataUsage.isLoading}>
                    <DataText bytes={currentMonthDataUsage?.data?.totalBillableBytes} decimals={2} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default BillingGroupDataUsagePaper;
