import React, { useMemo } from "react";
import { Box, TabPanel } from "@chakra-ui/react";
import {
    convertData,
    DataUsageBarChart,
    DataUsageEntry,
    DataUsagePieChart,
    Month,
    sum,
    UsageByPlmnEntry,
} from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { SimCard, SimCardDataUsageDataUsageInner } from "../../../../api";

export interface SimCardDataUsagePanelProps {
    simCard?: SimCard;
    showAllUsage?: boolean;
    selectedUsageYear: number | null;
    selectedUsageMonth: Month | null;
}

const mapToGraphableTotalDataUsage = (values: SimCardDataUsageDataUsageInner[] | undefined): DataUsageEntry[] => {
    const mapToDataUsageEntry = (value: SimCardDataUsageDataUsageInner): DataUsageEntry => {
        const totalBytes =
            value.imsis
                .map(imsiUsage => imsiUsage.plmns?.map(plmnUsage => plmnUsage.totalBytes).reduce(sum) || 0)
                .reduce(sum) || 0;

        return {
            year: value.year,
            month: Month[value.month],
            usage: convertData(totalBytes),
        };
    };
    return values ? values.map(mapToDataUsageEntry) : [];
};

const mapToGraphableMonthDataUsage = (
    values: SimCardDataUsageDataUsageInner[] | undefined,
    year: number,
    month: Month,
): UsageByPlmnEntry[] => {
    const mapToDataUsageEntry = (value: SimCardDataUsageDataUsageInner): UsageByPlmnEntry[] => {
        const plmnAccumulation: { [plmn: string]: number } = {};

        value.imsis.forEach(
            imsiUsage =>
                imsiUsage.plmns?.forEach(plmnUsage => {
                    if (!plmnAccumulation[plmnUsage.plmn]) {
                        plmnAccumulation[plmnUsage.plmn] = 0;
                    }

                    plmnAccumulation[plmnUsage.plmn] += plmnUsage.totalBytes;
                }),
        );

        return Object.entries(plmnAccumulation).map(entry => ({ plmn: entry[0], value: entry[1] }));
    };
    if (values) {
        const matchingYearMonth = values.find(value => value.year === year && value.month === month);
        return matchingYearMonth ? mapToDataUsageEntry(matchingYearMonth) : [];
    }
    return [];
};

const SimCardDataUsagePanel = ({
    simCard,
    selectedUsageYear,
    selectedUsageMonth,
    showAllUsage,
}: SimCardDataUsagePanelProps) => {
    const { simCardApi } = useApi();
    const { data: simCardDataUsage } = useQuery(
        ["simcards", simCard?.icc, "datausage"],
        () => simCardApi.getSimCardDataUsage({ icc: simCard?.icc || "" }).then(response => response.dataUsage),
        { enabled: simCard?.icc !== undefined },
    );
    const simCardTotalDataUsage = useMemo(() => mapToGraphableTotalDataUsage(simCardDataUsage), [simCardDataUsage]);
    const simCardMonthDataUsage = useMemo(() => {
        if (selectedUsageMonth && selectedUsageYear) {
            return mapToGraphableMonthDataUsage(simCardDataUsage, selectedUsageYear, selectedUsageMonth);
        }
        return [];
    }, [simCardDataUsage, selectedUsageYear, selectedUsageMonth]);

    return (
        <TabPanel>
            {showAllUsage && (
                <Box w={"100%"} h={"400px"}>
                    <DataUsageBarChart data={simCardTotalDataUsage} />
                </Box>
            )}
            {!showAllUsage && selectedUsageYear && selectedUsageMonth && (
                <Box w={"100%"} h={"400px"}>
                    <DataUsagePieChart
                        year={selectedUsageYear}
                        month={selectedUsageMonth}
                        data={simCardMonthDataUsage}
                        legend={plmn => <NetworkInfo plmn={plmn} />}
                    />
                </Box>
            )}
        </TabPanel>
    );
};

export default SimCardDataUsagePanel;
