/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSession
 */
export interface DataSession {
    /**
     * 
     * @type {string}
     * @memberof DataSession
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof DataSession
     */
    imsi: string;
    /**
     * 
     * @type {number}
     * @memberof DataSession
     */
    bytesDownloaded?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DataSession
     */
    bytesUploaded?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DataSession
     */
    totalBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DataSession
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof DataSession
     */
    country: string;
    /**
     * 
     * @type {Date}
     * @memberof DataSession
     */
    openedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataSession
     */
    lastUpdatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataSession
     */
    closedAt?: Date;
}

/**
 * Check if a given object implements the DataSession interface.
 */
export function instanceOfDataSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "totalBytes" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function DataSessionFromJSON(json: any): DataSession {
    return DataSessionFromJSONTyped(json, false);
}

export function DataSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'imsi': json['imsi'],
        'bytesDownloaded': !exists(json, 'bytesDownloaded') ? undefined : json['bytesDownloaded'],
        'bytesUploaded': !exists(json, 'bytesUploaded') ? undefined : json['bytesUploaded'],
        'totalBytes': json['totalBytes'],
        'plmn': json['plmn'],
        'country': json['country'],
        'openedAt': !exists(json, 'openedAt') ? undefined : (new Date(json['openedAt'])),
        'lastUpdatedAt': !exists(json, 'lastUpdatedAt') ? undefined : (new Date(json['lastUpdatedAt'])),
        'closedAt': !exists(json, 'closedAt') ? undefined : (new Date(json['closedAt'])),
    };
}

export function DataSessionToJSON(value?: DataSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'imsi': value.imsi,
        'bytesDownloaded': value.bytesDownloaded,
        'bytesUploaded': value.bytesUploaded,
        'totalBytes': value.totalBytes,
        'plmn': value.plmn,
        'country': value.country,
        'openedAt': value.openedAt === undefined ? undefined : (value.openedAt.toISOString()),
        'lastUpdatedAt': value.lastUpdatedAt === undefined ? undefined : (value.lastUpdatedAt.toISOString()),
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt.toISOString()),
    };
}

