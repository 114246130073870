/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Approximated location of the device based on cellular information
 * @export
 * @interface SimCardNetworkStateCellularPosition
 */
export interface SimCardNetworkStateCellularPosition {
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCellularPosition
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCellularPosition
     */
    latitude?: number;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateCellularPosition
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateCellularPosition interface.
 */
export function instanceOfSimCardNetworkStateCellularPosition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateCellularPositionFromJSON(json: any): SimCardNetworkStateCellularPosition {
    return SimCardNetworkStateCellularPositionFromJSONTyped(json, false);
}

export function SimCardNetworkStateCellularPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateCellularPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateCellularPositionToJSON(value?: SimCardNetworkStateCellularPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longitude': value.longitude,
        'latitude': value.latitude,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

