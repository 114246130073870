/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    itemId: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    itemType: string;
    /**
     * 
     * @type {Date}
     * @memberof Label
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    value?: string;
}

/**
 * Check if a given object implements the Label interface.
 */
export function instanceOfLabel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemId" in value;
    isInstance = isInstance && "itemType" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "key" in value;

    return isInstance;
}

export function LabelFromJSON(json: any): Label {
    return LabelFromJSONTyped(json, false);
}

export function LabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Label {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemId': json['itemId'],
        'itemType': json['itemType'],
        'createdAt': (new Date(json['createdAt'])),
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function LabelToJSON(value?: Label | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemId': value.itemId,
        'itemType': value.itemType,
        'createdAt': (value.createdAt.toISOString()),
        'key': value.key,
        'value': value.value,
    };
}

