/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Behaviour of an IMEI lock update operation.
 * Can be used to either `CREATE` or  `DELETE` IMEI lock rules.
 * @export
 */
export const ImeiLockUpdateBehaviour = {
    Create: 'CREATE',
    Delete: 'DELETE'
} as const;
export type ImeiLockUpdateBehaviour = typeof ImeiLockUpdateBehaviour[keyof typeof ImeiLockUpdateBehaviour];


export function ImeiLockUpdateBehaviourFromJSON(json: any): ImeiLockUpdateBehaviour {
    return ImeiLockUpdateBehaviourFromJSONTyped(json, false);
}

export function ImeiLockUpdateBehaviourFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiLockUpdateBehaviour {
    return json as ImeiLockUpdateBehaviour;
}

export function ImeiLockUpdateBehaviourToJSON(value?: ImeiLockUpdateBehaviour | null): any {
    return value as any;
}

