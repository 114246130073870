/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { WebhookSubscription } from './WebhookSubscription';
import {
    WebhookSubscriptionFromJSON,
    WebhookSubscriptionFromJSONTyped,
    WebhookSubscriptionToJSON,
} from './WebhookSubscription';

/**
 * Page of webhook subscriptions
 * @export
 * @interface WebhookSubscriptionPage
 */
export interface WebhookSubscriptionPage {
    /**
     * 
     * @type {Page}
     * @memberof WebhookSubscriptionPage
     */
    pageProperties: Page;
    /**
     * 
     * @type {Array<WebhookSubscription>}
     * @memberof WebhookSubscriptionPage
     */
    content: Array<WebhookSubscription>;
}

/**
 * Check if a given object implements the WebhookSubscriptionPage interface.
 */
export function instanceOfWebhookSubscriptionPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageProperties" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function WebhookSubscriptionPageFromJSON(json: any): WebhookSubscriptionPage {
    return WebhookSubscriptionPageFromJSONTyped(json, false);
}

export function WebhookSubscriptionPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookSubscriptionPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': PageFromJSON(json['pageProperties']),
        'content': ((json['content'] as Array<any>).map(WebhookSubscriptionFromJSON)),
    };
}

export function WebhookSubscriptionPageToJSON(value?: WebhookSubscriptionPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': ((value.content as Array<any>).map(WebhookSubscriptionToJSON)),
    };
}

