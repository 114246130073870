/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HardwareConnectionState = {
    Connected: 'CONNECTED',
    Disconnected: 'DISCONNECTED',
    Unknown: 'UNKNOWN'
} as const;
export type HardwareConnectionState = typeof HardwareConnectionState[keyof typeof HardwareConnectionState];


export function HardwareConnectionStateFromJSON(json: any): HardwareConnectionState {
    return HardwareConnectionStateFromJSONTyped(json, false);
}

export function HardwareConnectionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HardwareConnectionState {
    return json as HardwareConnectionState;
}

export function HardwareConnectionStateToJSON(value?: HardwareConnectionState | null): any {
    return value as any;
}

