/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImeiLock
 */
export interface ImeiLock {
    /**
     * 
     * @type {string}
     * @memberof ImeiLock
     */
    allowedImei: string;
}

/**
 * Check if a given object implements the ImeiLock interface.
 */
export function instanceOfImeiLock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allowedImei" in value;

    return isInstance;
}

export function ImeiLockFromJSON(json: any): ImeiLock {
    return ImeiLockFromJSONTyped(json, false);
}

export function ImeiLockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiLock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedImei': json['allowedImei'],
    };
}

export function ImeiLockToJSON(value?: ImeiLock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedImei': value.allowedImei,
    };
}

