/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebhookSubscriptionType } from './WebhookSubscriptionType';
import {
    WebhookSubscriptionTypeFromJSON,
    WebhookSubscriptionTypeFromJSONTyped,
    WebhookSubscriptionTypeToJSON,
} from './WebhookSubscriptionType';

/**
 * Information required to create a new webhook subscription.
 * @export
 * @interface CreateWebhookSubscription
 */
export interface CreateWebhookSubscription {
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    target: string;
    /**
     * 
     * @type {Array<WebhookSubscriptionType>}
     * @memberof CreateWebhookSubscription
     */
    subscribedTypes: Array<WebhookSubscriptionType>;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    description?: string;
    /**
     * The delivery timeout cannot exceed 15 seconds.
     * @type {string}
     * @memberof CreateWebhookSubscription
     */
    deliveryTimeout?: string | null;
}

/**
 * Check if a given object implements the CreateWebhookSubscription interface.
 */
export function instanceOfCreateWebhookSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "subscribedTypes" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateWebhookSubscriptionFromJSON(json: any): CreateWebhookSubscription {
    return CreateWebhookSubscriptionFromJSONTyped(json, false);
}

export function CreateWebhookSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWebhookSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'target': json['target'],
        'subscribedTypes': ((json['subscribedTypes'] as Array<any>).map(WebhookSubscriptionTypeFromJSON)),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'deliveryTimeout': !exists(json, 'deliveryTimeout') ? undefined : json['deliveryTimeout'],
    };
}

export function CreateWebhookSubscriptionToJSON(value?: CreateWebhookSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target': value.target,
        'subscribedTypes': ((value.subscribedTypes as Array<any>).map(WebhookSubscriptionTypeToJSON)),
        'name': value.name,
        'description': value.description,
        'deliveryTimeout': value.deliveryTimeout,
    };
}

