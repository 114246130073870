/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        UsagePackageType,
    } from '../models';
        import {
            UsagePackageTypeFromJSON,
            UsagePackageTypeToJSON,
        } from '../models';

            export interface UsagePackageApiGetAvailableUsagePackageTypesRequest {
                includeDeleted?: boolean;
            }

            export interface UsagePackageApiGetUsagePackageTypeRequest {
                usagePackageTypeId: string;
            }

        /**
        * 
        */
            export class UsagePackageApi extends runtime.BaseAPI {

            /**
                * Returns all available usage packages
                * Get usage package types
            */
            async getAvailableUsagePackageTypesRaw(requestParameters: UsagePackageApiGetAvailableUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UsagePackageType>>> {
            const queryParameters: any = {};

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsagePackageTypeFromJSON));
            }

            /**
                * Returns all available usage packages
                * Get usage package types
            */
                async getAvailableUsagePackageTypes(requestParameters: UsagePackageApiGetAvailableUsagePackageTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UsagePackageType>> {
                    const response = await this.getAvailableUsagePackageTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single usage package type by its id.
                * Get usage package type
            */
            async getUsagePackageTypeRaw(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling getUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Get a single usage package type by its id.
                * Get usage package type
            */
                async getUsagePackageType(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.getUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
