/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A representation of a IMSIs monthly data usage in a specific PLMN
 * @export
 * @interface DataUsageByPlmn
 */
export interface DataUsageByPlmn {
    /**
     * 
     * @type {string}
     * @memberof DataUsageByPlmn
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageByPlmn
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof DataUsageByPlmn
     */
    totalBytes: number;
}

/**
 * Check if a given object implements the DataUsageByPlmn interface.
 */
export function instanceOfDataUsageByPlmn(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "totalBytes" in value;

    return isInstance;
}

export function DataUsageByPlmnFromJSON(json: any): DataUsageByPlmn {
    return DataUsageByPlmnFromJSONTyped(json, false);
}

export function DataUsageByPlmnFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataUsageByPlmn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plmn': json['plmn'],
        'country': json['country'],
        'totalBytes': json['totalBytes'],
    };
}

export function DataUsageByPlmnToJSON(value?: DataUsageByPlmn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plmn': value.plmn,
        'country': value.country,
        'totalBytes': value.totalBytes,
    };
}

