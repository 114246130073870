/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2022*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * SIM card representation
 * @export
 * @interface SimCard
 */
export interface SimCard {
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    icc?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    customName?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    pin1?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    pin2?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    puk1?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    puk2?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    simConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    billingGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCard
     */
    esimId?: string;
}

/**
 * Check if a given object implements the SimCard interface.
 */
export function instanceOfSimCard(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardFromJSON(json: any): SimCard {
    return SimCardFromJSONTyped(json, false);
}

export function SimCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icc': !exists(json, 'icc') ? undefined : json['icc'],
        'customName': !exists(json, 'customName') ? undefined : json['customName'],
        'pin1': !exists(json, 'pin1') ? undefined : json['pin1'],
        'pin2': !exists(json, 'pin2') ? undefined : json['pin2'],
        'puk1': !exists(json, 'puk1') ? undefined : json['puk1'],
        'puk2': !exists(json, 'puk2') ? undefined : json['puk2'],
        'simConfigurationId': !exists(json, 'simConfigurationId') ? undefined : json['simConfigurationId'],
        'billingGroupId': !exists(json, 'billingGroupId') ? undefined : json['billingGroupId'],
        'esimId': !exists(json, 'esimId') ? undefined : json['esimId'],
    };
}

export function SimCardToJSON(value?: SimCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icc': value.icc,
        'customName': value.customName,
        'pin1': value.pin1,
        'pin2': value.pin2,
        'puk1': value.puk1,
        'puk2': value.puk2,
        'simConfigurationId': value.simConfigurationId,
        'billingGroupId': value.billingGroupId,
        'esimId': value.esimId,
    };
}

